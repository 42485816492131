export function getLinkTypes(guideline) {
  switch (guideline) {
    case 'iRECIST': {
      return [
        { value: 'TARGET', label: 'Target' },
        { value: 'NON-TARGET', label: 'Non-target' },
        { value: 'NL-TARGET', label: 'New (Target)' },
        { value: 'NL-NON-TARGET', label: 'New (Non-target)' },
      ];
    }
    default: {
      return [
        { value: 'TARGET', label: 'Target' },
        { value: 'NON-TARGET', label: 'Non-target' },
        { value: 'NEW', label: 'New' },
      ];
    }
  }
}

export function getResponseTypes(guideline) {
  switch (guideline) {
    case 'iRECIST': {
      return {
        TARGET_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'iCR', label: 'iCR' },
          { value: 'iPR', label: 'iPR' },
          { value: 'iSD', label: 'iSD' },
          { value: 'iUPD', label: 'iUPD' },
          { value: 'iCPD', label: 'iCPD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        NON_TARGET_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'iCR', label: 'iCR' },
          { value: 'NON-iCR/NON-iUPD', label: 'Non-iCR/Non-iUPD' },
          { value: 'iUPD', label: 'iUPD' },
          { value: 'iCPD', label: 'iCPD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        NEW_LESION_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'iCR', label: 'iCR' },
          { value: 'iUPD', label: 'iUPD' },
          { value: 'iCPD', label: 'iCPD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        USE_OF_STEROIDS: [
          { value: 'TBD', label: '------' },
          { value: 'N/A', label: 'N/A' },
        ],
        CLINICAL_STATUS: [
          { value: 'TBD', label: '------' },
          { value: 'N/A', label: 'N/A' },
          { value: 'Worse', label: 'Worse' },
          { value: 'Stable', label: 'Stable' },
          { value: 'Improved', label: 'Improved' },
        ],
        OVERALL_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'iCR', label: 'iCR' },
          { value: 'iPR', label: 'iPR' },
          { value: 'iSD', label: 'iSD' },
          { value: 'iUPD', label: 'iUPD' },
          { value: 'iCPD', label: 'iCPD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
      };
    }
    case 'RECIST11': {
      return {
        TARGET_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'CR', label: 'CR' },
          { value: 'PR', label: 'PR' },
          { value: 'SD', label: 'SD' },
          { value: 'PD', label: 'PD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        NON_TARGET_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'CR', label: 'CR' },
          { value: 'NON-CR/NON-PD', label: 'Non-CR/Non-PD' },
          { value: 'PD', label: 'PD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        NEW_LESION_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'Unequivocal', label: 'Unequivocal' },
          { value: 'Equivocal', label: 'Equivocal' },
          { value: 'Absent', label: 'Absent' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        USE_OF_STEROIDS: [
          { value: 'TBD', label: '------' },
          { value: 'N/A', label: 'N/A' },
        ],
        CLINICAL_STATUS: [
          { value: 'TBD', label: '------' },
          { value: 'N/A', label: 'N/A' },
          { value: 'Worse', label: 'Worse' },
          { value: 'Stable', label: 'Stable' },
          { value: 'Improved', label: 'Improved' },
        ],
        OVERALL_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'CR', label: 'CR' },
          { value: 'PR', label: 'PR' },
          { value: 'SD', label: 'SD' },
          { value: 'NON-CR/NON-PD', label: 'Non-CR/Non-PD' },
          { value: 'PD', label: 'PD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
      };
    }
    default: {
      return {
        TARGET_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'CR', label: 'CR' },
          { value: 'PR', label: 'PR' },
          { value: 'SD', label: 'SD' },
          { value: 'PD', label: 'PD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        NON_TARGET_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'CR', label: 'CR' },
          { value: 'NON-CR/NON-PD', label: 'Non-CR/Non-PD' },
          { value: 'PD', label: 'PD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        NEW_LESION_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'Unequivocal', label: 'Unequivocal' },
          { value: 'Equivocal', label: 'Equivocal' },
          { value: 'Absent', label: 'Absent' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
        USE_OF_STEROIDS: [
          { value: 'TBD', label: '------' },
          { value: 'N/A', label: 'N/A' },
          { value: 'None', label: 'None' },
          { value: 'Decreased', label: 'Decreased' },
          { value: 'Stable', label: 'Stable' },
          { value: 'Increased', label: 'Increased' },
        ],
        CLINICAL_STATUS: [
          { value: 'TBD', label: '------' },
          { value: 'N/A', label: 'N/A' },
          { value: 'Worse', label: 'Worse' },
          { value: 'Stable', label: 'Stable' },
          { value: 'Improved', label: 'Improved' },
        ],
        OVERALL_RESPONSE: [
          { value: 'TBD', label: '------' },
          { value: 'CR', label: 'CR' },
          { value: 'PR', label: 'PR' },
          { value: 'SD', label: 'SD' },
          { value: 'NON-CR/NON-PD', label: 'Non-CR/Non-PD' },
          { value: 'PD', label: 'PD' },
          { value: 'NE', label: 'NE' },
          { value: 'NED', label: 'NED' },
        ],
      };
    }
  }
}

export function getLinkLocations() {
  return LINK_LOCATIONS;
}
const LINK_LOCATIONS = [
  { value: 'BRAIN', label: 'Brain' },
  { value: 'BONE', label: 'Bone' },
  { value: 'NECK', label: 'Neck' },
  { value: 'LUNG', label: 'Lung' },
  { value: 'LYMPHNODE', label: 'Lymph Node' },
  { value: 'MEDIASTINUM', label: 'Mediastinum' },
  { value: 'CHEST WALL', label: 'Chest Wall' },
  { value: 'PLEURA', label: 'Pleura' },
  { value: 'PLEURAL CAVITY', label: 'Pleural Cavity' },
  { value: 'LIVER', label: 'Liver' },
  { value: 'KIDNEY', label: 'Kidney' },
  { value: 'ADRENAL GLAND', label: 'Adrenal Gland' },
  { value: 'PERITONEUM', label: 'Peritoneum' },
  { value: 'PERITONEAL CAVITY', label: 'Peritoneal Cavity' },
  { value: 'RETROPERITONEUM', label: 'Retroperitoneum' },
  { value: 'OMENTUM', label: 'Omentum' },
  { value: 'PELVIS', label: 'Pelvis' },
];

export default {
  getLinkTypes,
  getLinkLocations,
  getResponseTypes,
};
