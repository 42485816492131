export const MEASUREMENT_TECHNIQUE = {
  UNIDIMENSIONAL: 'UNIDIMENSIONAL',
  BIDIMENSIONAL: 'BIDIMENSIONAL',
  VOLUMETRIC: 'VOLUMETRIC',
};

export const TARGET_RESPONSE = {
  COMPLETE_RESPONSE: 'CR',
  PARTIAL_RESPONSE: 'PR',
  STABLE_DISEASE: 'SD',
  PROGRESSIVE_DISEASE: 'PD',
  NOT_EVALUABLE: 'NE',
  NONE: 'NED',
  iCR: 'iCR',
  iPR: 'iPR',
  iSD: 'iSD',
  iUPD: 'iUPD',
  iCPD: 'iCPD',
};

export const NON_TARGET_RESPONSE = {
  COMPLETE_RESPONSE: 'CR',
  NON_PROGRESSIVE_RESPONSE: 'NON-CR/NON-PD',
  PROGRESSIVE_DISEASE: 'PD',
  NOT_EVALUABLE: 'NE',
  NONE: 'NED',
  iCR: 'iCR',
  iNN: 'NON-iCR/NON-iUPD',
  iUPD: 'iUPD',
  iCPD: 'iCPD',
};

export const NEW_LESION_RESPONSE = {
  PRESENT: 'Unequivocal',
  UNCONFIRMED: 'Equivocal',
  ABSENT: 'Absent',
  NOT_EVALUABLE: 'NE',
  NONE: 'NED',
  iCR: 'iCR',
  iUPD: 'iUPD',
  iCPD: 'iCPD',
};

export const OVERALL_RESPONSE = {
  COMPLETE_RESPONSE: 'CR',
  PARTIAL_RESPONSE: 'PR',
  STABLE_DISEASE: 'SD',
  NON_PROGRESSIVE_RESPONSE: 'NON-CR/NON-PD',
  PROGRESSIVE_DISEASE: 'PD',
  NOT_EVALUABLE: 'NE',
  NONE: 'NED',
  iCR: 'iCR',
  iPR: 'iPR',
  iSD: 'iSD',
  iUPD: 'iUPD',
  iCPD: 'iCPD',
};

export const STEROIDS_USE = {
  NONE: 'None',
  DECREASED: 'Decreased',
  STABLE: 'Stable',
  INCREASED: 'Increased',
  NOT_APPLICABLE: 'N/A',
};

export const CLINICAL_STATUS = {
  WORSE: 'Worse',
  STABLE: 'Stable',
  IMPROVED: 'Improved',
  NOT_APPLICABLE: 'N/A',
};

export default {
  MEASUREMENT_TECHNIQUE,
  TARGET_RESPONSE,
  NON_TARGET_RESPONSE,
  NEW_LESION_RESPONSE,
  OVERALL_RESPONSE,
  STEROIDS_USE,
  CLINICAL_STATUS,
};
